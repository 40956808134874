import React from "react";
import {graphql} from "gatsby";
import BusquedasAbiertas from "../../components/empleos/busquedas-abiertas";
import EmpleosLayout from "../../components/empleos/EmpleosLayout";
import EmpleosList from "../../components/empleos/EmpleosList";

const pageData = {
    title: 'Trabajá con nosotros',
    description: 'Potenciá tu carrera laboral ingresando a la compañía líder del Mercado de Capitales. Conocé nuestras búsquedas de empleos y postulate.'
};

const BusquedasAbiertasPage = ({data}) => {
    return <EmpleosLayout pageData={pageData}>
        <EmpleosList jobPositions={data.allSearches.nodes} />
    </EmpleosLayout>
    
}

export const query = graphql`
  query {
    allSearches {
      nodes {
        id_search
        position_name
        timestamp
        timestamp_approve
        seniority_name
        description_task
        profile_description
        id_departament
        departament
        area
        name
        sector
      }
    }
  }
`

export default BusquedasAbiertasPage