import React, {useEffect, useState} from "react";
import {Link} from "gatsby";
import {Card, Carousel, Col, Container, Row} from "react-bootstrap";
import Icon from "../icon/icon";
import Select from 'react-select'
import Button from '../buttons/button'
import Mail from "../../imgs/share_mail.svg";
import Linkedin from "../../imgs/share_linkedin.svg";
import Whatsapp from "../../imgs/share_whatsapp.svg";

import ImgChica from "../../imgs/chica-oportunidades.jpg";
import './trabaja-con-nosotros.css';
import Pagination from "../pagination/pagination";
import slugify from "slugify";
import axios from "axios";
import truncate from "html-truncate";
import { SalWrapper } from "react-sal";

const BusquedasAbiertas = () => {
    const [data, setData] = useState([]);
    const [arrayDepartments, setArrayDepartments] = useState([]);
    const [totalData, setTotalData] = useState(0)
    const [departaments, setDepartaments] = useState([]);
    const [departamentSelected, setDepartamentSelected] = useState([])
    const [jobs, setJobs] = useState([])
    const [index, setIndex] = useState(0);
    const [pageSize] = useState(12);
    const [currentPage, setCurrentPage] = useState(1);
    const [positionOptions, setPositionOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getAllJobsPositions();
    }, []);

    const getAllJobsPositions = () => {
        setLoading(true);
        axios.get(`${process.env.GATSBY_RRHH_API_URL}/v1/positions/corporate`)
            .then(resp => {
                const serverData = resp.data || [];
                setData(serverData);
                setJobs(serverData);
                setTotalData(serverData.length);
                selectDepartaments(serverData);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    };

    const selectDepartaments = (SearchData) => {
        const dData = [];
        SearchData.forEach(d => {
            let search_departament = dData.find(dD => dD.value === d.id_departament);
            if (typeof search_departament === 'undefined' && null !== d.id_departament && null !== d.departament) {
                dData.push({value: d.id_departament, label: d.departament})
            }
        })
        setDepartaments(dData)
    }

    const setSearchPositionsData = serverData => {
        selectDepartaments(serverData);
    }

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    const preparePage = (data) => {
        data = data.filter(d => {
            let check = true;
            if (departamentSelected.length > 0) {
                check = departamentSelected.includes(d.id_departament);
            }
            return check;
        })

        setTotalData(data.length)

        if (data.length > 0) {
            const firstPageIndex = (currentPage - 1) * pageSize;
            const lastPageIndex = firstPageIndex + pageSize;
            setJobs(data.slice(firstPageIndex, lastPageIndex))
        } else {
            setJobs([])
        }
    }
    useEffect(() => {
        setSearchPositionsData(data);
    }, [data]);
    
    const getAllDepartments = () => {
        axios.get(process.env.GATSBY_RRHH_API_URL + '/v1/positions/departaments/public')
            .then(resp => {
                setArrayDepartments(resp.data.data)
            })
            .catch(error => {
                console.log(error)
            })
    }
    
    useEffect(() => {
        getAllDepartments()
    }, []);
    
    let departamentsData;
    if (arrayDepartments.length > 0) {
        departamentsData = arrayDepartments.map((departamento) => {
            return { value: departamento.id_departament, label: departamento.departament }
        });
    }
    
    useEffect(() => {
        preparePage(data)
    }, [currentPage, data, departamentSelected]);
    
    useEffect(() => {
        const uniquePositions = [...new Set(jobs.map(job => job.position_name))];
        const positionOptions = uniquePositions.map(position => ({ value: position, label: position }));
        setPositionOptions(positionOptions);
    }, [jobs]);
    
    const handleChange = (newValue, actionMeta) => {
        if (newValue.length === 0) {
            setJobs(data);
        } else {
            const selectedPositions = newValue.map((obj) => obj.value);
            const filteredJobs = data.filter(job => selectedPositions.includes(job.position_name));
            setJobs(filteredJobs);
        }
    };
    
    const page = (
        <>
            <section className="fullwidth seccion-empleos pb-0">
                <Container>
                <Row className="pt-5 pb-1 busqueda-text">
                        <h3 className="pb-0" >Estamos en constante crecimiento: </h3>
                        <p className="pb-4 base-text-2 title-page">
                            Explorá nuestras búsquedas abiertas y da el próximo paso en tu carrera.</p>
                    </Row>
                    <Row className='with-line aling-items-center'>
                        <Col md="6" lg="6" sm="12" xs="12">
                            <Select onChange={handleChange} isMulti placeholder='Filtrar por nombre de búsqueda' options={positionOptions}/>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className="fullwidth listado-empleos">
                <Container>
                    {
                        loading && (
                            <div className="text-center">
                               <span className="loader" />
                            </div>
                        ) 
                    }
                    {
                        !loading && (
                            jobs.length > 0 ? (
                                <>
                                    <Row>
                                        {jobs.map((d, i) => {
                                            return (
                                                <Col sm="12" md="6" lg="6" key={i}>
        
                                                    <Card border="light" className="card-empleo">
                                                        <Card.Body>
                                                            <span className="topic">
                                                                <strong style={{color:'#0E1E53'}}>{d.area}</strong>
                                                            </span>
                                                          
                                                                <h4 title={d.position_name}
                                                                    className="title">{d.position_name}</h4>
                                                                <p title={d.description_task}
                                                                   className="type">{truncate(d.description_task,90)}</p>
        
                                                            <div className="share" style={{color:'#0E1E53'}}>
                                                                <Link
                                                                    to={"/trabaja-con-nosotros/" + d.id_search + "-" + slugify(d.position_name, {lower: true})}>
                                                                <span>Ver más</span>
                                                                </Link>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            )
                                        })}
                                    </Row>
        
                                    <Row className="py-5 text-center">
                                        <Pagination
                                            className="pagination-bar"
                                            currentPage={currentPage}
                                            totalCount={totalData}
                                            pageSize={pageSize}
                                            onPageChange={page => setCurrentPage(page)}
                                        />
                                    </Row>
                                </>
                            ) : (
                                <Row>
                                    <Col xs="12">
        
                                        <h4><strong>En este momento, no hay búsquedas abiertas para este departamento. Por favor, dejanos tu CV</strong></h4>
                                    </Col>
                                </Row>
                            )
                        )
                    }
                    

                </Container>

            </section>

            <section className="section-home-five pt-0 pb-0">
                <article className='row article-top g-0'>
                    <div className='col p-0 bg-blue d-flex flex-column justify-content-center align-items-center'>
                        <div className="bg-blue-text">
                            <h3>Completá tus datos</h3>
                            <p>para futuras búsquedas</p>
                            <div className="text-center ">
                            <Button id="ver_busquedas" className="btn-busquedas btn-empelos-white" variant="third" text="QUIERO POSTULARME" onClick={() => { window.open("https://people.balanz.com/aplicar/38?_gl=1*13vb616*_ga*MTEyNDAwMzA2Mi4xNjI2NzIwODI0*_ga_XT4Q9ZDDZ6*MTcyODkxODI5OC40NjEuMS4xNzI4OTE4MzA2LjUyLjAuMA..", "_blank") }} />
                            </div>
                        </div>
                    </div>
                    <div className='col p-0'>
                        <img src={ImgChica} className="img-work" alt="Employee working"/>
                    </div>
                </article>
            </section>

            <Col xs="12" className="d-flex justify-content-center py-5">
                <div>
                    <h6>Búsqueda laboral equitativa Ley 6471-21. El empleador sólo podrá solicitarle la información estrictamente necesaria para el desempeño en el trabajo que se le ofrece.</h6>
                </div>
            </Col>
        </>
    )
    return page
}

export default BusquedasAbiertas;